<template>
  <div class="talent-home-stepsSec">
    <div class="steps pt-100 pb-100" id="steps">
      <div class="container">
        <div class="row">
          <h4 class="text-white !tw-font-bold lg!:tw-text-[40px]  tw-text-[36px] tw-text-start lg:!tw-text-center  tw-mb-4 ">
            {{ $t("talent.howToGet") }}
            <span class="text-yellow"> {{ $t("talent.started") }}</span>
          </h4>
          <p class="mx-auto tw-text-start lg:!tw-text-center col-lg-6">
            {{ $t("talent.howToGetStartedText") }}
          </p>
          <ul
            class="pl-0 row d-flex steps-ul text-start pt-100 justify-content-center tw-m-auto tw-px-2 "
          >
            <li class="col-12 col-md-2">
              <span class="step-number"> 1 </span>
              <h4 class="text-white">
                {{ $t("talent.registerAccount") }}
              </h4>
              <p class="d-flex lg:tw-flex-col tw-flex-row " >
                {{ $t("talent.registerAccountText1") + " " }}
                <router-link  to="/talent-signup" class="nav-link tw-text-white tw-contents tw-mx-1 lg:tw-mx-0 tw-underline">{{ $t("talent.registerAccountText2") }}</router-link>
                {{ " "+  $t("talent.registerAccountText3") }}
                <!--  Register to join
                <a href="#" class="text-white"
                  >Squadio's Talent Network
                  {{ $t("talent.howToGet") }}
                </a> -->
              </p>
              <div
                class="right-arrow"
                :class="{ 'hiring-arrow-rotate-first': $i18n.locale == 'ar' }"
              >
                <img
                  src="../../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>

            <li class="col-12 col-md-2">
              <span class="step-number"> 2 </span>
              <h4 class="text-white">
                {{ $t("talent.technicalExam") }}
              </h4>
              <p>
                {{ $t("talent.technicalExamText") }}
              </p>
              <div class="right-arrow"
              :class="{ 'hiring-arrow-rotate': $i18n.locale == 'ar' }"
              >
                <img
                  src="../../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>

            <li class="col-12 col-md-2">
              <span class="step-number"> 3 </span>
              <h4 class="text-white">
                {{ $t("talent.technicalInterview") }}
              </h4>
              <p>
                {{ $t("talent.technicalInterviewText") }}
              </p>
              <div class="right-arrow"
              :class="{ 'hiring-arrow-rotate': $i18n.locale == 'ar' }"
              >
                <img
                  src="../../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>

            <li class="col-12 col-md-2">
              <span class="step-number"> 4 </span>
              <h4 class="text-white">
                {{ $t("talent.personalizedMatching") }}
              </h4>
              <p>
                {{ $t("talent.personalizedMatchingText") }}
              </p>
              <div
              v-if="$i18n.locale == 'ar'"
              class="right-arrow"
              :class="{
                'hiring-arrow-rotate hiring-arrow-rotate-last':
                  $i18n.locale == 'ar',
              }"
            >
              <img src="../../../../../assets/images/talent/homeScreen/Arrow_Right.svg"/>
            </div>
            </li>
          </ul>

          <div class="mt-5 text-center">
            <ApplyViewBtn rounded />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./stepsSec.scss";
import ApplyViewBtn from "../applyViewBtn/applyViewBtn.vue";

export default {
  components: {
    ApplyViewBtn,
  },
};
</script>

<style lang="scss" scoped></style>
