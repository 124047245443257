<template>
  <!-- intro section -->

  <div class="talent-home-introSec overflow-hidden">
    <div class="intro topIntro position-relative talent-intro">
      <div
        class="container align-items-center py-5"
      >
        <div class="row align-items-center text-center text-lg-start position-relative z-index-10">
          <div class="col-lg-6 col-12 mt-5">
            <h1 class="text-white bold poppins-font">
              <span>
                {{ $t("talent.find") }}
                <span class="text-yellow">
                  {{ $t("talent.yourDreamJob") }}</span
                >
                {{ $t("talent.here") }}
              </span>
            </h1>
            <p class="mb-4 text-white">
              {{ $t("talent.easiestWayToGetDreamJob") }}
            </p>
            <div class="mt-4 mb-4 applyViewBtn">
              <ApplyViewBtn />
            </div>
          </div>

          <div
            class="col-lg-5 offset-lg-1 col-12 position-relative text-center mt-4 order-first order-lg-last"
          >
            <img
              src="../../../../../assets/images/talent/homeScreen/hero-section.png"
              alt=""
              class="img-fluid topIntroimg"
              data-aos="fade-left"
            />
          </div>
        </div>

        <div class="icon-bottom">
          <img
            src="../../../../../assets/images/talent/homeScreen/white-shape.svg"
          />
        </div>
        <!-- </div> -->
        <div class="icon-left z-index-1">
          <img
            src="../../../../../assets/images/talent/homeScreen/squadio-shape-2.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./introSec.scss";
import { mapGetters } from "vuex";
import Steps from "../../../../../enums/WizzardSteps";
import ApplyViewBtn from "../applyViewBtn/applyViewBtn.vue";

export default {
  components: {
    ApplyViewBtn,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    localStorage: () => localStorage,
  },
  methods: {
    handleRedirection(path) {
      this.$router.push({ path });
    },
    goToProfile() {
      if (this.user.step == Steps.PROFILE) {
        this.$router.push({ name: "Profile" });
      } else {
        this.$router.push({ name: "Wizard" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.z-index-10 {
  z-index: 10;
}
.z-index-1 {
  z-index: 1;
}
</style>
