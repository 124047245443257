<template>
  <div class="talent-home-partnersSec">
    <div class="Partners-div pt-100 pb-100" id="Partners-div">
      <div class="container">
        <div class="row align-items-center text-center text-lg-start">
          <div
            class="col-lg-5 offset-lg-1 position-relative text-center order-lg-last mb-4"
          >
            <div class="row">
              <img
                src="../../../../../assets/images/talent/homeScreen/Current-Vacancies-2x.png"
                alt=""
                class="img-fluid topIntroimg mx-auto"
                data-aos="fade-left"
              />
            </div>
          </div>

          <div class="col-lg-5 col-md-12 mb-5" data-aos="fade-right">
            <h2 class="mb-4">
              {{ $t("talent.weAreHiring") }}
            </h2>
            <p>
              {{ $t("talent.createAccount") }}
            </p>
            <ApplyViewBtn />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./partnersSec.scss";
import ApplyViewBtn from "../applyViewBtn/applyViewBtn.vue";
export default {
  components: {
    ApplyViewBtn,
  },
};
</script>

<style lang="scss" scoped></style>