var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"talent-home-serviceSec",class:{'talents-home': _vm.talentsHome}},[_c('div',{staticClass:"services-section pt-100 pb-100 position-relative",attrs:{"id":"services-section","data-bs-spy":"scroll"}},[_c('div',{staticClass:"container z-index-1"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-6 poppins-font"},[_c('h2',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t("talent.moreThan"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("talent.moreThanResume"))+" ")]),_c('h4',{staticClass:"h5 text-white"},[_vm._v(" "+_vm._s(_vm.$t("talent.moreThanResumetext"))+" ")])])]),_c('div',[_c('ul',{staticClass:"services-items p-0 d-flex"},[_c('li',{staticClass:"col"},[_c('div',[_vm._m(0),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("talent.exclusiveOpportunities"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("talent.exclusiveOpportunitiesText"))+" ")])]),_c('div',{staticClass:"mt-5"},[_vm._m(1),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("talent.upToDateTechnologies"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("talent.upToDateTechnologiesText"))+" ")])])]),_c('li',{staticClass:"col"},[_c('div',[_vm._m(2),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("talent.smartRoleMatching"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("talent.smartRoleMatchingText"))+" ")])]),_c('div',{staticClass:"mt-5"},[_vm._m(3),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("talent.professionalFlexibility"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("talent.rightClientRole"))+" ")])])]),_c('li',{staticClass:"col"},[_c('div',[_vm._m(4),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("talent.competitiveCompensation"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("talent.competitiveCompensationText"))+" ")])]),_c('div',{staticClass:"mt-5"},[_vm._m(5),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("talent.workRemotely"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("talent.workRemotelyText"))+" ")])])])])])]),_c('div',{staticClass:"topbg"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"serv-icon"},[_c('img',{attrs:{"src":require("../../../../../assets/images/talent/homeScreen/ic_Opportunities.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"serv-icon"},[_c('img',{attrs:{"src":require("../../../../../assets/images/talent/homeScreen/ic_technical-support.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"serv-icon"},[_c('img',{attrs:{"src":require("../../../../../assets/images/talent/homeScreen/ic_Top-notch.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"serv-icon"},[_c('img',{attrs:{"src":require("../../../../../assets/images/talent/homeScreen/ic_Working_Hours.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"serv-icon"},[_c('img',{attrs:{"src":require("../../../../../assets/images/talent/homeScreen/ic_Compensation.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"serv-icon"},[_c('img',{attrs:{"src":require("../../../../../assets/images/talent/homeScreen/ic_Working_Remotely.svg")}})])
}]

export { render, staticRenderFns }