<template>
 <div class="wizard">
        <Navbar />
        <IntroSection />
        <ServiceSection />
        <StepsSection />
        <PartnersSection />
        <Footer />
    </div>
   
</template>

<script>
import { mapGetters } from "vuex";
import IntroSection from '../Home/components/introSec/introSec.vue';
import ServiceSection from '../Home/components/serviceSec/serviceSec.vue';
import StepsSection from '../Home/components/stepsSec/stepsSec.vue';
import PartnersSection from '../Home/components/partnersSec/partnersSec.vue';
import Footer from '../Home/components/footer/footer.vue';
import Navbar from '../Home/components/navbar/navbar.vue';


export default {
    components: {
        IntroSection,
        ServiceSection,
        StepsSection,
        PartnersSection,
        Navbar,
        Footer
        },
    computed: {
        ...mapGetters({
            user: "auth/getUser"
        })
    },
  
}
</script>
